@import "primeng/resources/primeng.min.css";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";
@import "node_modules/@acal-frontend/admin-ui/src/assets/sass/layout/_variables";
@import "node_modules/@acal-frontend/admin-ui/src/assets/sass/layout/_layout";
@import "node_modules/@acal-frontend/admin-ui/src/assets/sass/overrides/_overrides";
@import "node_modules/@acal-frontend/admin-ui/src/assets/theme/tailwind-light/theme";

.ozd--status-badge {
  &.ozd--status-claimed,
  &.ozd--status-open {
    border: none;
    background: #feedaf;
    color: #8a5340;
  }

  &.ozd--status-closed {
    border: none;
    background: #666;
    color: #fff;
  }

  &.ozd--status-declined {
    border: none;
    background: #ffcdd2;
    color: #c63737;
  }

  &.ozd--status-ee_initd,
  &.ozd--status-ongoing {
    border: none;
    background: #b3e5fc;
    color: #23547b;
  }

  &.ozd--status-open {
    border: none;
    background: #eccfff;
    color: #694382;
  }

  &.ozd--status-accepted,
  &.ozd--status-delivered,
  &.ozd--status-resolved {
    border: none;
    background: #c8e6c9;
    color: #256029;
  }

  &.ozd--status-returned {
    border: none;
    background: #ffd8b2;
    color: #805b36;
  }
}

.p-dropdown {
  &.ozd--p-dropdown-padding-1rem {
    .p-dropdown-label {
      &.p-inputtext {
        padding: 1rem;
      }
    }
  }
}
